import React, { ReactNode, createContext, useContext, useState } from "react";

interface NewBoardContext {
  showCreateBoard: boolean;
  setShowCreateBoard: (show: boolean) => void;
}

const NewBoardContext = createContext<NewBoardContext>({} as NewBoardContext);

export const NewBoardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [show, setShow] = useState(false);

  return (
    <NewBoardContext.Provider
      value={{ showCreateBoard: show, setShowCreateBoard: (show: boolean) => setShow(show) }}
    >
      {children}
    </NewBoardContext.Provider>
  );
};

export const useNewBoard = () => {
  return useContext(NewBoardContext);
};
